import { TFunction } from "i18next";
import { MetaTagsDto } from "~/application/dtos/seo/MetaTagsDto";

type SiteTags = {
  title: string;
  description: string;
  keywords: string;
  image: string;
  thumbnail: string;
  twitterCreator: string;
  twitterSite: string;
};
export function getDefaultSiteTags({ t }: { t: TFunction }): SiteTags {
  return {
    title: t("seo.title"),
    description: t("seo.description"),
    keywords: "Natural Language Processing,AI-Powered Insights,Data Analysis,Interactive Querying,Intuitive Data Exploration,AI",
    image: "https://yahooder.sirv.com/datalang/seo/2/new-cover.jpg",
    thumbnail: "https://yahooder.sirv.com/datalang/seo/2/thumbnail.jpg",
    twitterCreator: "@AlexandroMtzG",
    twitterSite: "",
  };
}

export function defaultSeoMetaTags({ t, slug }: { t: TFunction; slug?: string }): MetaTagsDto {
  const siteTags = getDefaultSiteTags({ t });
  if (slug === "/pricing") {
    siteTags.title = `${t("front.pricing.title")} | ${siteTags.title}`;
    siteTags.description = t("front.pricing.headline");
  } else if (slug === "/blog") {
    siteTags.title = `${t("blog.title")} | ${siteTags.title}`;
    siteTags.description = t("blog.headline");
  } else if (slug === "/contact") {
    siteTags.title = `${t("front.contact.title")} | ${siteTags.title}`;
    siteTags.description = t("front.contact.headline");
  } else if (slug === "/newsletter") {
    siteTags.title = `${t("front.newsletter.title")} | ${siteTags.title}`;
    siteTags.description = t("front.newsletter.headline");
  } else if (slug === "/changelog") {
    siteTags.title = `${t("front.changelog.title")} | ${siteTags.title}`;
    siteTags.description = t("front.changelog.headline");
  }

  return parseMetaTags(siteTags);
}

function parseMetaTags(tags: SiteTags): MetaTagsDto {
  return [
    { title: tags.title },
    { name: "description", content: tags.description },
    { name: "keywords", content: tags.keywords },
    { property: "fb:app_id", content: "1437962246789912" },
    { property: "og:title", content: tags.title },
    { property: "og:type", content: "website" },
    { property: "og:image", content: tags.image },
    { property: "og:card", content: "summary_large_image" },
    { property: "og:description", content: tags.description },
    { property: "twitter:image", content: tags.thumbnail },
    { property: "twitter:card", content: "summary_large_image" },
    { property: "twitter:creator", content: tags.twitterCreator ?? "" },
    { property: "og:creator", content: tags.twitterCreator },
    { property: "twitter:site", content: tags.twitterSite ?? "" },
    { property: "twitter:title", content: tags.title },
    { property: "twitter:description", content: tags.description },
  ];
}
